import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { GlobalStyles, Avatar, Box, Divider, List, ListItem, ListItemButton, listItemButtonClasses, Typography, Paper, Dialog, DialogTitle } from "@mui/material";
import { RestaurantOutlined, MemoryOutlined, AssignmentRounded, AssignmentTurnedInOutlined, LocalPoliceOutlined, StorefrontOutlined, CalculateOutlined, GavelOutlined, LightModeOutlined, KitchenOutlined, Language, KeyboardArrowDown, KeyboardArrowRight, LockPersonOutlined } from '@mui/icons-material';
import { closeSidebar } from "../utils";
import { setMenu } from '../reducer/menu';
import { useMsal } from '@azure/msal-react';

const menuIcons: { [key: number]: JSX.Element } = {
  0: <AssignmentRounded color="success"/>,
  1: <StorefrontOutlined sx={{ color: "#636B74" }}/>,
  2: <CalculateOutlined sx={{ color: "#636B74" }}/>,
  3: <GavelOutlined sx={{ color: "#636B74" }}/>,
  4: <LightModeOutlined sx={{ color: "#636B74" }}/>,
  5: <KitchenOutlined sx={{ color: "#636B74" }}/>,
  6: <Language sx={{ color: "#636B74" }}/>,
  7: <RestaurantOutlined sx={{ color: "#636B74" }}/>,
  100: <LocalPoliceOutlined color="success"/>,
  200: <AssignmentTurnedInOutlined color="success"/>,
  500: <MemoryOutlined color="success"/>,
  510: <MemoryOutlined color="success"/>,
  900: <LockPersonOutlined color="success"/>,
  910: <LockPersonOutlined color="success"/>
}

const menuItems = [
  { index: 100, name: '월간 영업/제도 안내', status: 'OPEN', welcome: "OFC Value Meeting에서 공유되는 월간 영업안내자료를 확인해보세요.\n\n**10월 1주차**\n- 24년 빼빼로데이 봉제인형 운영안\n- 담배 10월 액상전자담배 뷰즈GO 통합 행사 보고\n- 스몰다이닝 3종, 감동란 행사, 이연복 상품 운영 및 마케팅 계획\n- 24, 25년 감귤 운영안, 군고구마 운영안\n\n**10월 2주차**\n- 동절기 시즌 상품(핫팩 & 방한용품) 안내\n- 반품 상품 관련 안내\n\n**10월 3주차**\n- 경영주 코칭을 위한 운영 제도안내\n- 24년 인허가별 위생교육 갱신 안내\n- 서비스 백일장 우수작 안내\n- 서비스 우수점 선정 안내\n- 24년 하반기 모니터링 결과 안내\n\n**10월 4주차**\n- 경영주 필독 안내문\n  - 주요 매출 지수 및 상승 카테고리\n  - 빼빼로데이 행사 안내\n  - 동절기 '더 핫한 점포 만들기 요약\n  - 11월 소비기한 경과 다빈도 중분류\n  - 주간 매출 핵심 상품\n  - 오픈런 하는 편의점 25"},
  { index: 0, name: '이오보감', status: 'OPEN', 
    child: [
        { index: 1, name: '가맹계약', status: 'OPEN', welcome: "가맹계약 파트에서는 다음 내용들을 확인할 수 있습니다.\n\n1. 계약서 작성 방법 및 전자계약 매뉴얼\n2. 신규타입과 구타입 특징\n3. 지원 제도 및 상생협력 방안\n4. 수익 산출 구조\n5. 채권 및 담보, 미송대응 및 채권보전방법\n6. 재계약, 명의변경/타입변경 프로세스\n7. 본부인수 및 폐점" }
      , { index: 2, name: '가맹회계', status: 'OPEN', welcome: "가맹회계 파트에서는 다음 내용들을 확인할 수 있습니다.\n\n**매출,매입 프로세스**\n**결제수단**\n**수불관리**\n**정산보고서**\n**편의점 세무관리**\n**부가가치세 및 종합소득세**\n**기타**(마스터 품의 연동, 송금, 가맹점 부가세 등)" }
      , { index: 3, name: '편의점 법률', status: 'OPEN', welcome: "편의점 법률 파트에서는 다음 내용들을 확인할 수 있습니다.\n\n**노무관련 법률**(가맹사업법, 근로기준법, 4대보험)\n\n**기타 법률**\n- 담배사업법 및 청소년보호법\n- 식품위생법\n- 화재/침수/재해\n- 형법(강도/폭행/상해/절도)\n- 여신전문금융업법 및 수표법\n- 자원 절약 및 재활용 촉진법\n- 사고보고 기준\n\n**상생지원제도**\n- 인센티브 제도\n- 경조사 지원제도\n- 법률 상담 서비스\n- 가맹점 보험\n- 장기운영 지원혜택 등"  }
      , { index: 4, name: '오픈/양수', status: 'OPEN', welcome: "오픈/양수 파트에서는 다음 내용들을 확인할 수 있습니다.\n\n**신규 오픈 프로세스**\n**신규점/양수도점 오픈 지원**\n-  오픈 지원 기준\n-  오픈 판촉\n-  초도재고처리\n\n**양수도 프로세스**\n-  양수도 당일 진행업무\n-  양수도 D+1월 이후 진행업무\n-  양수도점 점포만들기\n\n**개점지원 업무 프로세스**"  }
      , { index: 5, name: '시설실무', status: 'OPEN', welcome: "시설실무 파트에서는 다음 내용들을 확인할 수 있습니다.\n\n1. 시설물 구분 및 관리\n2. 시설 설계 및 배치\n3. 설비 및 유지보수\n4. 시설 안전 관리\n5. 시설 업무 처리 프로세스\n6. 시설 사고예방 및 AS, 보험 접수\n7. 양수도/폐점/리뉴얼에 따른 시설 관련 업무"  }
      , { index: 6, name: '시스템 활용', status: 'OPEN', welcome: "시스템 활용 파트에서는 점포경영시스템의 다음 메뉴에 관한 설명을 확인할 수 있습니다.\n\n**점포경영시스템**\n-  OFC 메인: OFC TODO, 전략분류, 메뉴 검색\n-  발주관리\n-  단품관리\n-  매출관리\n-  수익관리\n-  점포관리\n-  자동발주, F/F 자동발주\n\n**모바일 점포경영**\n-  점포경영지표\n-  매출현황\n-  점포현황\n-  발주장려금 수취\n-  상품추천\n-  공산품 결품\n-  퀵커머스\n"  }
      , { index: 7, name: '위생/선도', status: 'OPEN', welcome: "위생/선도 파트에서는 다음 내용들을 확인할 수 있습니다.\n\n1. 상품 소비기한 관리\n2. 상품별 적정 온도 관리\n3. 식품 표시사항 관리\n4. 카운터FF 운영 관리\n5. 안전상비의약품 관리\n6. 선도관리의 목적 및 방법"}
    ]
  },
];

export default function Sidebar() {
  const { accounts } = useMsal();

  const navigate = useNavigate();
  const selectedMenu = useSelector((state: any) => state.menu);
  const userInfo = useSelector((state: any) => state.user);

  const [topMenuopen, setTopMenuopen] = useState(true);
  const [alert, setAlert] = useState(false);

  const handleAlertClose = () => {
    setAlert(false);
  };
  
  if(userInfo.role === 'admin' && menuItems[menuItems.length - 1].index < 900){
    menuItems.push({ index: 500, name: 'AI 아리 (GPT)', status: 'OPEN', welcome: "GPT 최신버전 GPT-4o를 체험해 보라구" });
    menuItems.push({ index: 510, name: 'AI 아리 (Claude)', status: 'OPEN', welcome: "Claude 최신버전 Claude 3.5 Sonnet을 체험해 보라구" });
    menuItems.push({ index: 900, name: '인덱스 관리', status: 'OPEN', welcome: "" });
    menuItems.push({ index: 910, name: '문서 관리', status: 'OPEN', welcome: "" });
  }

  const handleClick = () => {
    setTopMenuopen(!topMenuopen);
  };

  const dispatch = useDispatch();

  const handleMenuClick = (index: number, name: string, status: string, welcome: string) => {
    if(status === "TBD"){
      setAlert(true);
    }else{
      dispatch(setMenu({index, name, welcome}));
    }
  };

  useEffect(() => {
    // if(selectedMenu.selectedIndex <= 100) navigate('/');
    // else if(selectedMenu.selectedIndex < 900) navigate('/gpt');
    if(selectedMenu.selectedIndex < 900) navigate('/');
    else if(selectedMenu.selectedIndex === 900) navigate('/console/index');
    else if(selectedMenu.selectedIndex === 910) navigate('/console/document');
  } , [navigate, selectedMenu]);


  return (
    <Paper
      className="Sidebar"
      sx={{ position: { xs: "fixed", sm: "sticky" },
        transform: {
          xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))",
          sm: "none",
        },
        transition: "transform 0.4s, width 0.4s",
        zIndex: 10000,
        height: "100dvh",
        width: "var(--Sidebar-width)",
        top: 0,
        // p: 2,
        flexShrink: 0,
        display: "flex",
        flexDirection: "column",
        borderRight: "1px solid",
        borderColor: "divider",
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ":root": {
            "--Sidebar-width": "220px",
            [theme.breakpoints.up("lg")]: {
              "--Sidebar-width": "240px",
            },
          },
        })}
      />
      <Box
        className="Sidebar-overlay"
        sx={{
          position: "fixed",
          zIndex: 9998,
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          opacity: "var(--SideNavigation-slideIn)",
          backgroundColor: "var(--joy-palette-background-backdrop)",
          transition: "opacity 0.4s",
          transform: {
            xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))",
            lg: "translateX(-100%)",
          },
        }}
        onClick={() => closeSidebar()}
      />
      <Box sx={{ display: "flex", gap: 1, alignItems: "center", p: 2 }}>
        <Avatar 
          // variant="outlined" 
          // size="lg"
          src="./logo.png" />
        <img src="./title-small.png" alt="logo" style={{ width: '145px', height: '35px' }} />
        {/* <Typography fontFamily="'Poetsen One', sans-serif" fontSize={35}>OFC GPT</Typography> */}
      </Box>
      <Box
        sx={{
          minHeight: 0,
          overflow: "hidden auto",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
          },
          px: 2
        }}
      >
        <List sx={{ "--List-nestedInsetStart": "30px" }} >
          {menuItems.map((item, index) => (
            <div key={index}>
              <ListItem key={index} sx={{flexDirection: 'flex-start', px: 0, py: 0.5}} secondaryAction={item.child ? (topMenuopen ? <KeyboardArrowDown /> : <KeyboardArrowRight />) : null}>
                <ListItemButton
                  onClick={!item.child ? () => handleMenuClick(item.index, item.name, item.status, item.welcome) : handleClick}
                  selected={item.index === selectedMenu.selectedIndex ? true : false}
                  
                  >
                  {menuIcons[item.index]}
                    <Typography 
                      sx={{
                        fontSize: 15, fontWeight: 600, color: item.status === "OPEN" ? "#000" : "#AEB4BA",
                        WebkitFontSmoothing: "antialiased"
                      }}>
                      {item.name}
                    </Typography>
                </ListItemButton>
              </ListItem>
                {topMenuopen && item.child && (<List component="div">
                  {item.child.map((child, childIndex) => {
                    return (
                      <ListItem key={childIndex} sx={{flexDirection: 'flex-start', pl: 2, py: 0}}>
                        <ListItemButton onClick={() => handleMenuClick(child.index, child.name, child.status, child.welcome)}
                          selected={child.index === selectedMenu.selectedIndex ? true : false}>
                          {menuIcons[child.index]}
                          {/* <ListItemContent> */}
                            <Typography 
                              // level="title-sm"
                              sx={{
                                fontSize: 15, fontWeight: 600, color: child.status === "OPEN" ? "#000" : "#AEB4BA",
                                WebkitFontSmoothing: "antialiased"
                              }}>
                              {child.name}
                            </Typography>
                          {/* </ListItemContent> */}
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>)}
              </div>
          ))}
        </List>
      </Box>
      <Divider sx={{}}/>
      <Box sx={{ display: "flex", gap: 1, alignItems: "center", px: 2, py: 1 }}>
        <Avatar />
        <Box sx={{ minWidth: 0, flex: 1 }}>
          <Typography sx={{fontSize: '0.9rem'}}>{accounts[0]['name']}</Typography>
        </Box>
      </Box>
      <Dialog
        open={alert}
        onClose={handleAlertClose}
      >
        <DialogTitle id="alert-dialog-title">
          {"메뉴 준비중입니다."}
        </DialogTitle>
      </Dialog>
    </Paper>
  );
}