import * as React from "react";
// import Avatar from "@mui/matarial/Avatar";
// import Stack from "@mui/matarial/Stack";
import Typography from "@mui/material/Typography";
import MemoryOutlinedIcon from "@mui/icons-material/MemoryOutlined";
import { Avatar, Stack } from "@mui/material";
// import { UserProps } from "../types";

type MessagesPaneHeaderProps = {
  menuTitle: string | null;
};

export default function MessagesPaneHeader(props: MessagesPaneHeaderProps) {
  const { menuTitle } = props;
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        borderBottom: "1px solid",
        borderColor: "divider",
        backgroundColor: "background.body",
      }}
      py={{ xs: 2, md: 2 }}
      px={{ xs: 1, md: 2 }}
    >
      <Stack direction="row" pl={{ xs: 1, md: 0 }} spacing={1} alignItems="center">
        <Avatar
          // size="smamll"
          color="primary"
          sx={{ bgcolor: "#00D4EA", color: "white" }}
        >
          <MemoryOutlinedIcon />
        </Avatar>

        <div>
          <Typography variant="h2">
            {menuTitle}
          </Typography>
        </div>
      </Stack>
    </Stack>
  );
}
