import axios from 'axios';

const url = process.env.NODE_ENV === 'development' ? 'http://localhost:8080': 'https://gsr-dt-api-p001.azurewebsites.net';

const NewApi = axios.create({
	baseURL: url,
	withCredentials: true,
});

NewApi.defaults.withCredentials = true;

NewApi.interceptors.request.use(
	function (config) {
			const token = sessionStorage.getItem("idToken");
			if (token) {
					config.headers['Authorization'] = `Bearer ${token}`;
			}
			config.headers['Content-Type'] = 'application/json';
			return config;
	},
	function (error) {
			return Promise.reject(error);
	}
);


NewApi.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		return Promise.reject(error);
	}
);

export default NewApi;
