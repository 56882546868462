import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MenuStateType {
  selectedIndex: number;
  selectedName: string;
  selectedWelcome: string;
}

const initialState: MenuStateType = {
  selectedIndex: 100,
  selectedName: "월간 영업/제도 안내",
  selectedWelcome: "OFC Value Meeting에서 공유되는 월간 영업안내자료를 확인해보세요.\n\n**10월 1주차**\n- 24년 빼빼로데이 봉제인형 운영안\n- 담배 10월 액상전자담배 뷰즈GO 통합 행사 보고\n- 스몰다이닝 3종, 감동란 행사, 이연복 상품 운영 및 마케팅 계획\n- 24, 25년 감귤 운영안, 군고구마 운영안\n\n**10월 2주차**\n- 동절기 시즌 상품(핫팩 & 방한용품) 안내\n- 반품 상품 관련 안내\n\n**10월 3주차**\n- 경영주 코칭을 위한 운영 제도안내\n- 24년 인허가별 위생교육 갱신 안내\n- 서비스 백일장 우수작 안내\n- 서비스 우수점 선정 안내\n- 24년 하반기 모니터링 결과 안내\n\n**10월 4주차**\n- 경영주 필독 안내문\n  - 주요 매출 지수 및 상승 카테고리\n  - 빼빼로데이 행사 안내\n  - 동절기 '더 핫한 점포 만들기 요약\n  - 11월 소비기한 경과 다빈도 중분류\n  - 주간 매출 핵심 상품\n  - 오픈런 하는 편의점 25",
};

const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    setMenu: (state, action: PayloadAction<{ index: number; name: string; welcome: string }>) => {
      state.selectedIndex = action.payload.index;
      state.selectedName = action.payload.name;
      state.selectedWelcome = action.payload.welcome;
    },
  },
});

export const { setMenu } = menuSlice.actions;
export default menuSlice.reducer;