export type UserProps = {
  name: string;
  username: string;
};

export type MessageProps = {
  id: string;
  sender: string | undefined;
  account: string | null;
  content: string;
  timestamp: string;
};

export type ChatProps = {
  id: string;
  sender: string;
  messages: MessageProps[];
};

export type documentType = {
  categoryCode: string | undefined;
  id: string | undefined;
  sourcepage: number | undefined;
  sourcefile: string | undefined;
  content: string | undefined;
  bizTerms: string | undefined;
  altTerms: string | undefined;
};

export const initialDocument: documentType = {
  categoryCode: "",
  id: "",
  sourcepage: 0,
  sourcefile: "",
  content: "",
  bizTerms: "",
  altTerms: ""
};

export type categoryType = {
  categoryCode: string | undefined;
  categoryName: string | undefined;
  storageAccount: string | undefined;
  blobContainer: string | undefined;
  fileName: string | undefined;
};

export const initCategory: categoryType = {
  categoryCode: undefined,
  categoryName: undefined,
  storageAccount: undefined,
  blobContainer: undefined,
  fileName: undefined,
};