import { useState, useEffect } from 'react';

import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import '../../index.css';

import { getIndexList } from '../../api/admin';
import Sidebar from '../../components/Sidebar';
import Header from '../../components/Header';
import MessagesPaneHeader from '../../components/MessagesPaneHeader';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/AriStore';
import { documentType, initialDocument } from '../../types';
import Box from '@mui/material/Box/Box';
import Paper from '@mui/material/Paper/Paper';
import FormControl from '@mui/material/FormControl/FormControl';
import FormLabel from '@mui/material/FormLabel/FormLabel';
import Button from '@mui/material/Button/Button';
import Typography from '@mui/material/Typography/Typography';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select/Select';
import Timeline from '@mui/lab/Timeline/Timeline';
import TimelineItem from '@mui/lab/TimelineItem/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector/TimelineConnector';
import TimelineDot from '@mui/lab/TimelineDot/TimelineDot';
import TimelineContent from '@mui/lab/TimelineContent/TimelineContent';
import OutlinedInput from '@mui/material/OutlinedInput/OutlinedInput';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import theme from '../../style/theme';

const callIndexList = async () => {
  const res = await getIndexList();
  return res.data;
}

export default function IndexAdmin() {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const [indexData, setIndexData] = useState<any[]>([]);
  const [category, setCategory] = useState<string | null>(null);
  const [document, setDocument] = useState<documentType>(initialDocument);

  const menuInfo = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    if (indexData.length === 0) {
      const index = callIndexList();
      index.then((resolvedIndex: any[]) => setIndexData(resolvedIndex));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string);
  };

  return (
    <>
      <Sidebar />
      <Header />
      <Box component="main" className="MainContent" sx={{ flex: 1 }}>
        <Paper
          key={menuInfo.selectedIndex}
          sx={{
            height: { xs: '100dvh', lg: '100dvh' },
            display: 'flex',
            flexDirection: 'column',
            pt: { xs: 'var(--Header-height)', sm: 0 },
          }}
        >
          <>
            <MessagesPaneHeader menuTitle={menuInfo.selectedName} />
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', overflow: 'hidden' }}>
              <Box sx={{ display: 'flex', overflowY: 'auto', backgroundColor: '#F0F4F8', flexDirection: 'row', p: 1, gap: 1, minHeight: "50px", alignItems: 'center' }}>
                <FormControl>
                  <FormLabel sx={{ fontSize: "13px", fontWeight: 700 }}>대분류</FormLabel>
                  <Select sx={{ fontSize: "13px", minWidth: 200 }} defaultValue="ofc-handbook" >
                    {indexData.map((item: any) => (
                      <MenuItem key={item.indexCode} value={item.indexCode}>{item.indexName}</MenuItem >
                    ))}
                  </Select>
                </FormControl>
                <NavigateNextIcon sx={{ color: '#FF0000' }} />
                <FormControl>
                  <FormLabel sx={{ fontSize: "13px", fontWeight: 700 }}>중분류</FormLabel>
                  <Select sx={{ fontSize: "13px", minWidth: 200 }} defaultValue="" onChange={handleCategoryChange}>
                    {indexData.map((item: any) => (
                      item.category.map((cat: any) => (
                        <MenuItem key={cat.categoryCode} value={cat.fileName}>{cat.categoryName}</MenuItem>
                      ))
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ flex: 1, display: 'flex', flexDirection: 'row', overflow: "scroll" }}>
                {/* 좌측 패널 */}
                <Box sx={{
                  width: '600px', overflowY: 'auto', height: 750, fontSize: '12px',
                  margin: "16px 0px 0px 16px",
                  border: "1px solid #e0e0e0",
                  borderRadius: "6px",
                  boxShadow: "rgb(11, 107, 203) 0px 0px 0px 0px inset",
                }}>
                  {!category ? null : (<Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                    <Viewer defaultScale={SpecialZoomLevel.PageFit} plugins={[defaultLayoutPluginInstance]} fileUrl={`https://gsrstorageaccountp001.blob.core.windows.net/init/${category}`} />
                  </Worker>)}
                </Box>
                {/* 용어 패널 */}
                <Box sx={{
                  width: '250px', overflowY: 'auto', height: "730px",
                  margin: "16px 0px 0px 16px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}>
                  <FormControl sx={{ marginTop: 1 }}>
                    <FormLabel sx={{ fontSize: "13px", fontWeight: 700 }}>문서 분할</FormLabel>
                    <OutlinedInput value={document?.bizTerms?.split(',').join('\n')} sx={{ fontSize: "13px" }} onChange={(event) => {
                      setDocument(prevDoc => {
                        if (!prevDoc) return prevDoc;
                        return {
                          ...prevDoc,
                          bizTerms: event.target.value.split(',').join('\n'),
                        };
                      })
                    }}
                      placeholder='예) 1-5, 6, 7, 8-10'
                      minRows={15}
                      maxRows={15}
                    />
                    <FormLabel sx={{ pt: 2, color: "#5E514D", fontSize: "13px", fontWeight: 400 }}>※ 누락되는 페이지가 없도록 주의하세요</FormLabel>
                    <FormLabel sx={{ color: "#5E514D", fontSize: "13px", fontWeight: 400 }}>※ 문서 분할을 변경할 경우, 마크업 변환이 초기화 됩니다.</FormLabel>
                  </FormControl>
                  <FormControl sx={{ pt: 0, justifyContent: "flex-end" }}>
                    <Button variant="contained" sx={{ width: "100%", alignSelf: 'flex-end', borderRadius: 'sm' }} /* onClick={handleUpdateMarkdown} */ >등록 요청</Button>
                  </FormControl>
                </Box>
                {/* 진행 현황 패널 */}
                <Box sx={{
                  width: '450px', overflowY: 'auto', height: "730px",
                  margin: "16px 0px 0px 16px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                }}>
                  <Typography sx={{ fontWeight: 700, pb: 2 }}>진행 현황</Typography>
                  <Timeline position='alternate'>
                    <TimelineItem sx={{minHeight: '50px'}}>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>Index Creation</TimelineContent>
                    </TimelineItem>
                    <TimelineItem sx={{minHeight: '50px'}}>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>Category Registration</TimelineContent>
                    </TimelineItem>
                    <TimelineItem sx={{minHeight: '50px'}}>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>Contents upload</TimelineContent>
                    </TimelineItem>
                    <TimelineItem sx={{minHeight: '50px'}}>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>Chunking</TimelineContent>
                    </TimelineItem>
                    <TimelineItem sx={{minHeight: '50px'}}>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>Transform</TimelineContent>
                    </TimelineItem>
                    <TimelineItem sx={{minHeight: '50px'}}>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>Biz Terms Mapping</TimelineContent>
                    </TimelineItem>
                    <TimelineItem sx={{minHeight: '50px'}}>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>Context Review</TimelineContent>
                    </TimelineItem>
                    <TimelineItem sx={{minHeight: '50px'}}>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>Biz Terms Review</TimelineContent>
                    </TimelineItem>
                    <TimelineItem sx={{minHeight: '50px'}}>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>Embedding</TimelineContent>
                    </TimelineItem>
                    <TimelineItem sx={{minHeight: '50px'}}>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>Generate Synthetic Dataset</TimelineContent>
                    </TimelineItem>
                    <TimelineItem sx={{minHeight: '50px'}}>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>Evaluation</TimelineContent>
                    </TimelineItem>
                    <TimelineItem sx={{minHeight: '50px'}}>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>Complete</TimelineContent>
                    </TimelineItem>
                  </Timeline>
                </Box>
              </Box>
            </Box>
          </>
        </Paper>
      </Box>
    </>
  );
}