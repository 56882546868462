import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import chatReducer from '../reducer/chat';
import menuReducer from '../reducer/menu';
import userInfoReducer from '../reducer/user';

export const store = configureStore({
	reducer: {
		chat: chatReducer,
		menu: menuReducer,
		user: userInfoReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}).concat(),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;


export default store;