import axios, { AxiosError } from 'axios';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
export interface ChatCompletionType {
    messageType: string | null;
    content: string | null
    finishReason: string | null;
}

const initialState: ChatCompletionType = {
	messageType: null,
	content: null,
	finishReason: null,
};

export const chatSlice = createSlice({
	name: 'chat',
	initialState,
	reducers: {
		chatCompletionReset: (state: ChatCompletionType, action: PayloadAction) => {
			state.messageType = null;
			state.content = null;
			state.finishReason = null;
		},
	},
});

export const { chatCompletionReset } = chatSlice.actions;

export default chatSlice.reducer;
