import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import ChatBubble from './ChatBubble';
import MessageInput from './MessageInput';
import MessagesPaneHeader from './MessagesPaneHeader';
import { MessageProps } from '../types';
import { useMsal } from '@azure/msal-react';
import { RootState } from '../store/AriStore';
import { generateRandomString } from '../utils';
import { api } from '../api/streamFetch';

function formattedDate() {
  const formatter = new Intl.DateTimeFormat('ko-KR', options);
  const [{ value: month }, , { value: day }, , { value: hour }, , { value: minute }] = formatter.formatToParts(new Date());
  const formattedDate = `${month}.${day} ${hour}:${minute}`;

  return formattedDate;
}

function sleep(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const options: Intl.DateTimeFormatOptions = {
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,
};

export default function MessagesPane() {
  const menuInfo = useSelector((state: RootState) => state.menu);

  const [chatMessages, setChatMessages] = useState<MessageProps[]>([{
    id: "DEFAULT",
    sender: 'ASSISTANT',
    account: null,
    content: menuInfo.selectedWelcome,
    timestamp: formattedDate(),
  }]);

  const [textAreaValue, setTextAreaValue] = useState('');
  const chatCompletionData = useSelector((state: any) => state.chat);
  const { accounts } = useMsal();

  const fetchData = async () => {
    setChatMessages([
      ...chatMessages,
      {
        id: "DEFAULT",
        sender: 'ASSISTANT',
        account: null,
        content: 'Loading...',
        timestamp: new Intl.DateTimeFormat('ko-KR', options).format(new Date())
      },
    ]);

    const response = await api.post(menuInfo.selectedIndex !== 510 ? `/stream/gpt` : `/stream/claude`, 
      { account: accounts[0]['username'], message: chatMessages, menuIndex: menuInfo.selectedIndex }
    );

    if (response.body) {
      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      while (true) {
        const { done, value } = await reader.read();

        if (done) {
          console.log('Receive Stream Data Done!');
          break;
        }
        const chunk = decoder.decode(value, { stream: true });
        const newMessages = chunk.split('\n\n')
          .map(message => {
            return message.split('data:').join('');
          });
        // eslint-disable-next-line no-loop-func
        msg = msg + newMessages.join('');

        await sleep(300);

        setChatMessages([
          ...chatMessages,
          {
            id: chatMessages[chatMessages.length - 1].id,
            sender: 'ASSISTANT',
            account: null,
            content: msg ? msg : 'Loading.....',
            timestamp: formattedDate(),
          },
        ]);
      }
    }
  };

  var msg: string | null = '';

  useEffect(() => {
    if (chatMessages.length > 1 && chatMessages[chatMessages.length - 1]?.sender !== 'ASSISTANT') {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatMessages]);

  useEffect(() => {
    setChatMessages([{
      id: "DEFAULT",
      sender: 'ASSISTANT',
      account: null,
      content: menuInfo.selectedWelcome,
      timestamp: formattedDate(),
    }])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuInfo.selectedIndex]);


  useEffect(() => {
    if (chatMessages[chatMessages.length - 1]?.sender !== 'ASSISTANT') {
      setChatMessages([
        ...chatMessages,
        {
          id: generateRandomString(30),
          sender: chatCompletionData.messageType,
          account: accounts[0]['username'],
          content: chatCompletionData.content,
          timestamp: formattedDate(),
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatCompletionData.content]); // Removed dispatch from dependency array

  return (
    <Paper
      key={menuInfo.selectedIndex} // Ensure re-render when chat prop changes
      sx={{
        height: { xs: '100dvh', lg: '100dvh' },
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'background.level1',
        pt: { xs: 'var(--Header-height)', sm: 0 },
      }}
    >
      <>
        <MessagesPaneHeader menuTitle={menuInfo.selectedName} />
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            minHeight: 0,
            px: 2,
            py: 3,
            overflowY: 'scroll',
            flexDirection: 'column-reverse',
            backgroundColor: '#F0F4F8',
          }}
        >
          <Stack spacing={2} justifyContent="flex-end">
            {chatMessages.map((message: MessageProps, index: number) => {
              const isYou = message.sender !== 'ASSISTANT';
              return (
                <Stack
                  key={index}
                  direction="row"
                  spacing={2}
                  flexDirection={isYou ? 'row-reverse' : 'row'}
                >
                  <ChatBubble variant={isYou ? 'sent' : 'received'} {...message} />
                </Stack>
              );
            })}
          </Stack>
        </Box>
        <MessageInput
          textAreaValue={textAreaValue}
          setTextAreaValue={setTextAreaValue}
          onSubmit={() => {
            setChatMessages([
              ...chatMessages,
              {
                id: generateRandomString(30),
                sender: accounts[0]['name'],
                account: accounts[0]['username'],
                content: textAreaValue,
                timestamp: formattedDate(),
              },
            ]);
          }}
        />
      </>
    </Paper>
  );
}
