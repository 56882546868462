import { PropsWithChildren } from "react";
import { useSelector } from "react-redux";

import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { AuthenticationResult } from "@azure/msal-browser";

import { loginRequest } from "../../authConfig";

import Container from "@mui/material/Container/Container";
import Box from "@mui/material/Box/Box";
import Typography from "@mui/material/Typography/Typography";
import Button from "@mui/material/Button/Button";
import Card from "@mui/material/Card/Card";
import CardMedia from "@mui/material/CardMedia/CardMedia";

export default function TwoSidedLayout({
  children,
  reversed,
}: PropsWithChildren<{ reversed?: boolean }>) {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const userInfo = useSelector((state: any) => state.user);

  const handleLogin = async () => {
    try {
      const loginResponse: AuthenticationResult = await instance.loginPopup(
        loginRequest
      );
      sessionStorage.setItem("idToken", loginResponse.idToken);
    } catch (error) {
      console.error("Login Canceled:", error);
    }
  };

  const handleLogout = async () => {
    await instance.logoutPopup();
  };

  const Welcome = () => {
    return (
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }} >
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: {md: 'center', lg: "center"}, gap: 0 }} >
          <Card sx={{ maxWidth: 320, border: 'none', boxShadow: 'none', backgroundColor: 'transparent', mt: 2, mb: 5 }}>
            <CardMedia
              component="img"
              image="/title.png"
            />
          </Card>
          <Typography fontFamily="sans-serif" sx={{ fontSize: '17px', pr: 2, fontWeight: 600 }} >12월 23일, 편의점사업부 정식 오픈됩니다.</Typography>
          <Typography fontFamily="'Poetsen One', sans-serif" sx={{ fontSize: '25px', pr: 2 }} >- Coming Soon -</Typography>
          {/* <Button onClick={() => handleLogin()} variant="contained" color="primary" sx={{ fontWeight: 700, mt: 2, width: '100%', py: 1.5, borderRadius: 3}}> Let's go meet Ari! </Button> */}
        </Box>
      </Box>
    );
  };

  const AccessDeny = () => {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            gap: 2,
          }}
        >
          <Typography
            variant="h1"
            fontFamily="'Poetsen One', sans-serif"
            letterSpacing="0.5rem"
          >
            Access Denied.
          </Typography>
        </Box>
        <Typography
          /* fontSize="md" */ /* textColor="text.secondary" */ lineHeight="lg"
        >
          "접속 권한이 없거나, 인증 정보가 만료되었습니다. 다시 로그인해주세요."
        </Typography>
        <Button /* size="lg" */ onClick={() => handleLogout()}>Logout</Button>
      </>
    );
  };

  return (
    <Container
      sx={{
        position: 'relative',
        minHeight: "100dvh",
        minWidth: "100dvw",
        display: "flex",
        flexDirection: { xs: "column", lg: "row" },
        justifyContent: 'center',
        gap: 2,
        alignItems: "center",
      }}
    >
          <Card sx={{ maxWidth: 520, borderRadius: 4, boxShadow: '0 10px 20px rgba(0, 0, 0, 0.5)' }}>
            <CardMedia
              component="img"
              image="/AI-Ari-Helmet-wide.webp"
              alt="Paella dish"
            />
          </Card>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          maxWidth: "50ch",
          textAlign: "center",
          flexShrink: 999,
          alignItems: 'center',
          textWrap: 'initial',
          minWidth: "520px",
          // pb: 10,
        }}
      >
        {!isAuthenticated ? (
          <Welcome />
        ) : userInfo.roll === null ? (
          <AccessDeny />
        ) : <Welcome />}
        <Typography sx={{ pt: "50px", fontSize: "0.9rem" }}>
          Copyright ⓒ 2024 GS Retail Co.,Ltd. All Rights Reserved.
        </Typography>
      </Box>
    </Container>
  );
}
