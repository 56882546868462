import { PublicClientApplication, InteractionRequiredAuthError, Configuration } from "@azure/msal-browser";

export class AuthService {
    private msalInstance: PublicClientApplication;

    private productRedirectUrl = 
    process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000/redirct'
    : `https://ari.gsrdt.com/redirct`;

    constructor() {
        // MSAL 설정
        const msalConfig: Configuration = {
            auth: {
                clientId: "51344919-c710-4f0d-b863-25f5020d89dd", // Azure AD에 등록된 클라이언트 ID
                authority: "https://login.microsoftonline.com/4645c25c-9775-4d77-a736-700fab766116", // 테넌트 ID
                redirectUri: this.productRedirectUrl, // 리다이렉트 URI
            },
            cache: {
                cacheLocation: "sessionStorage", // 또는 "sessionStorage"
                storeAuthStateInCookie: false,    // 모던 브라우저 지원
                secureCookies: true
            }
        };

        // MSAL 인스턴스 초기화
        this.msalInstance = new PublicClientApplication(msalConfig);
        
        // initialize 호출 추가
        this.msalInstance.initialize().catch(error => {
            console.error("MSAL 초기화 실패:", error);
        });
    }

    async getAccessToken(): Promise<string> {
        await this.msalInstance.initialize();
        
        try {
            const account = this.msalInstance.getAllAccounts()[0];
            
            if (!account) {
                throw new Error("No active account! Please sign in first.");
            }

            // 커스텀 API에 대한 토큰만 요청
            const silentRequest = {
                scopes: ["api://51344919-c710-4f0d-b863-25f5020d89dd/User.Read"],
                account: account
            };
            
            const response = await this.msalInstance.acquireTokenSilent(silentRequest);
            return response.accessToken;
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                await this.msalInstance.acquireTokenRedirect({
                    scopes: ["api://51344919-c710-4f0d-b863-25f5020d89dd/User.Read"]
                });
            }
            throw error;
        }
    }
}