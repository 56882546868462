import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    // 점경 메인 컬러
    primary: { main: "#003E80" },
    // 우리동네GS 메인 컬러
    secondary: { main: "#007CFF" },
    // GS Shop 레드
    error: { main: "#CE0C47" },
    // 아리 메인 컬러
    warning: { main: "#FFE04F" },
    // GS25 밝은 회색
    info: { main: "#D9D9D9", dark: "#636B74" },
    // GS25 OFC채널 메인 컬러
    success: { main: "#036672" },
    background: {
      default: "#F8F8F8",
      paper: "#FFF",
    },
  },
  typography: {
    fontFamily: '"Noto Sans KR", -apple-system',
    h1: {
      fontSize: "5rem",
      fontWeight: 500,
      color: "#242424",
    },
    h2: {
      fontSize: "1.2rem",
      fontWeight: 700,
      color: "#242424",
    },
    h3: {
      fontSize: "1rem",
      fontWeight: 700,
      color: "#242424",
    },
    h4: {
      fontSize: "1rem",
      fontWeight: 700,
      color: "#242424",
    },
    h5: {
      fontSize: "1rem",
      color: "#242424",
    },
    h6: {
      fontSize: "1rem",
      fontWeight: 500,
    },
    body1: {
      fontSize: "0.8rem",
      color: "#242424",
    },
    body2: {
      fontSize: "0.9rem",
      color: "#242424",
    }
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          py: 10,
          gap: 4,
          alignItems: "center",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFF",
          fontWeight: 500,
          color: "#000",
          boxShadow: "none",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: "#242424",
          fontWeight: 700,
          fontSize: "1rem",
          minHeight: '35px',
          height: '35px',
          "&.Mui-selected": {
            color: "#242424",
            borderBottom: "2px solid #007CFF",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: '35px',
          height: '35px',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "30px",
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          "&.MuiListItemButton-root": {
            padding: "8px 0px",
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "#E7E7E7",
          color: "#E7E7E7",
          border: "1px solid #E7E7E7",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          "&.MuiListItemText-primary": {
            fontSize: "1rem",
            fontWeight: 500,
          },
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          minWidth: "0px",
          px: "0px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          minWidth: "0px",
          "& .MuiButton-startIcon": {
            Margin: "0px",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          "&.MuiPopover-paper": {
            borderRadius: "10px",
            minWidth: "200px",
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          "& .MuiButtonBase-root": {
            borderRadius: "10px",
            border: "none",
            padding: "10px 20px 10px 10px",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          padding: "4px 10px",
          "& .MuiSelect-select": {
            padding: "4px 10px",
          },
        },
      },
    },
  },
});

export default theme;
