import {useMemo, useState} from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import { MessageProps } from '../types';
import DOMPurify from "dompurify";
import { renderToStaticMarkup } from "react-dom/server";
import styles from "../css/chatBubble.module.css";

import ReactMarkdown from 'react-markdown';
import rehypeRaw from "rehype-raw";
import remarkMath from 'remark-math';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import theme from '../style/theme';

type ChatBubbleProps = MessageProps & {
  onCitationClicked: (filePath: string) => void;
  variant: 'sent' | 'received';
};

type HtmlParsedAnswer = {
  answerHtml: string;
  citations: string[];
};

const setEvaluation = async (id: string, status: string ) => {
  const api_host = process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : 'https://gsr-dt-api-p001.azurewebsites.net';
  const evaluation = status === '' ? '' : status;
  const response = await fetch(`${api_host}/evaluation?id=${id}&isLike=${evaluation}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${sessionStorage.getItem("idToken")}`,
    },
    credentials: 'include',
    mode: 'cors',
  });
};

// const handleClickCitation = (message: string) => (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
//   alert(message);
//   console.log(message);
//   // event.preventDefault();
// };

const parseAnswerToHtml = (answer: string): HtmlParsedAnswer => {
  const citations: string[] = [];

  let parsedAnswer = answer.trim();

  let lastIndex = parsedAnswer.length;
  for (let i = parsedAnswer.length - 1; i >= 0; i--) {
      if (parsedAnswer[i] === "]") {
          break;
      } else if (parsedAnswer[i] === "[") {
          lastIndex = i;
          break;
      }
  }
  const truncatedAnswer = parsedAnswer.substring(0, lastIndex);
  parsedAnswer = truncatedAnswer;

  const parts = parsedAnswer.split(/\[([^\]]+)\]/g);

  const fragments: string[] = parts.map((part, index) => {
      if (index % 2 === 0) {
          return part;
      } else {
          let citationIndex: number;
          if (citations.indexOf(part) !== -1) {
              citationIndex = citations.indexOf(part) + 1;
          } else {
              citations.push(part);
              citationIndex = citations.length;
          }
          return renderToStaticMarkup(
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a className="supContainer" title={part} >
                <sup>{citationIndex}</sup>
                  {/* <sup>{part.replace(".pdf", "")}</sup> */}
              </a>
          );
      }
  });

  return {
      answerHtml: fragments.join(""),
      citations
  };
}


export default function ChatBubble(props: ChatBubbleProps) {
  const { content, variant, timestamp, sender, id, onCitationClicked } = props;
  const isSent = variant === 'sent';
  
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isLiked, setIsLiked] = useState<boolean>(false);
  const [isUnLiked, setIsUnLiked] = useState<boolean>(false);
  const [isCelebrated, setIsCelebrated] = useState<boolean>(false);

  const parsedAnswer = useMemo(() => parseAnswerToHtml(props.content), [props]);

  const sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml);

  const handleContentCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(content);
      alert('클립보드에 복사되었습니다!');
    } catch (err) {
      console.error('클립보드 복사 실패: ', err);
    }
    setIsCelebrated((prevState) => !prevState);
  };

  return (
    <Box sx={{ maxWidth: '60%', minWidth: 'auto' }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{ mb: 0.25 }}
      >
        <Typography variant='body1'>
          {sender === 'ASSISTANT' ? sender : sender}
        </Typography>
        <Typography variant='body1'>{timestamp}</Typography>
      </Stack>
      <Box
          sx={{ position: 'relative' }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Paper
            elevation={0}
            sx={{
              maxWidth: '800px',
              p: 1.25,
              color: isSent ? '#FFF' : '#000',
              borderRadius: 4,
              borderTopRightRadius: isSent ? 0 : 14,
              borderTopLeftRadius: isSent ? 14 : 0,
              backgroundColor: 
                isSent
                ? theme.palette.secondary.dark
                : 'background.body',
            }}
          > 
            { <ReactMarkdown
              className="z-ui-markdown"
              children={parsedAnswer.answerHtml}
              remarkPlugins={[remarkMath, remarkGfm, remarkBreaks]}
              rehypePlugins={[rehypeRaw]}
              components={{
                code({ node, inline, className, children, ...rest }: any) {
                  const match = /language-(\w+)/.exec(className || '')
                  // console.log('code chat', match)
                  return !inline && match ? (
                    <SyntaxHighlighter
                      {...rest}
                      children={children}
                      language={match[1]}
                      PreTag="div"
                    />
                  ) : (
                    <code {...props} className={`code-line`}>
                      {children}
                    </code>
                  )
                }
              }}
            /> }
            {!!parsedAnswer.citations.length && (
                    <span>
                        <span className={styles.citationLearnMore}>참고자료 :</span>
                        {parsedAnswer.citations.map((x, i) => {
                            // const path = getCitationFilePath(x);
                            return (
                                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                <>
                                  <br/>
                                  <a key={i} className={styles.citation} title={x} onClick={() => onCitationClicked(x)}>
                                    {`[${++i}] ${x}`}
                                  </a>
                                </>
                            );
                        })}
                    </span>
            )}
          </Paper>
          {(!isSent && id !== "DEFAULT") && (
            <Stack
              direction="row"
              justifyContent={isSent ? 'flex-end' : 'flex-start'}
              spacing={0.5}
              sx={{
                position: 'absolute',
                top: '50%',
                p: 1.5,
                ...(isSent
                  ? {
                      left: 0,
                      transform: 'translate(-100%, -50%)',
                    }
                  : {
                      right: 0,
                      transform: 'translate(100%, -50%)',
                    }),
              }}
            >
              <IconButton
                onClick={() => {
                  setIsLiked((prevState) => {
                    if (!prevState === true) {
                      setIsUnLiked(false);
                      setEvaluation(id, 'LIKE');
                    }else{
                      setEvaluation(id, '');
                    }
                    return !prevState;
                  });
                }}
              >
                {isLiked ? <ThumbUpAltIcon/> : <ThumbUpOffAltIcon />}
              </IconButton>
              <IconButton
                onClick={() => {
                  setIsUnLiked((prevState) => {
                    if (!prevState === true) {
                      setIsLiked(false);
                      setEvaluation(id, 'DISLIKE');
                    }else{
                      setEvaluation(id, '');
                    }
                    return !prevState;
                  });
                }}
              >
                {isUnLiked ? <ThumbDownAltIcon/> : <ThumbDownOffAltIcon />}
              </IconButton>
              <IconButton
                onClick={handleContentCopyClick}
              >
                {isCelebrated ? <ContentCopyIcon /> : <ContentCopyIcon />}
              </IconButton>
            </Stack>
          )}
        </Box>
    </Box>
  );
}
