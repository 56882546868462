import { useState} from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { MessageProps } from '../types';

import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import Button from '@mui/material/Button/Button';
import theme from '../style/theme';

type ChatBubbleProps = MessageProps & {
  variant: 'sent' | 'received';
};

export default function ChatBubble(props: ChatBubbleProps) {
  const { content, variant, timestamp, sender, id } = props;
  const isSent = variant === 'sent';
  const [isLiked, setIsLiked] = useState<boolean>(false);
  const [isCelebrated, setIsCelebrated] = useState<boolean>(false);

  const handleContentCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(content);
      alert('클립보드에 복사되었습니다!');
    } catch (err) {
      console.error('클립보드 복사 실패: ', err);
    }
    setIsCelebrated((prevState) => !prevState);
  };

  return (
    <Box sx={{ maxWidth: '60%', minWidth: 'auto' }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{ mb: 0.25 }}
      >
        <Typography variant='body1'>
          {sender === 'ASSISTANT' ? sender : sender}
        </Typography>
        <Typography variant='body1'>{timestamp}</Typography>
      </Stack>
      <Box
          sx={{ position: 'relative' }}
        >
          <Paper
            elevation={0}
            sx={{
              maxWidth: '800px',
              p: 1.25,
              color: isSent ? '#FFF' : '#000',
              borderRadius: 4,
              borderTopRightRadius: isSent ? 0 : 14,
              borderTopLeftRadius: isSent ? 14 : 0,
              backgroundColor: 
                isSent
                ? theme.palette.secondary.dark
                : 'background.body',
            }}
          > 
            { <ReactMarkdown
              className="z-ui-markdown"
              children={content}
              remarkPlugins={[remarkMath, remarkGfm, remarkBreaks]}
              components={{
                code({ node, inline, className, children, ...rest }: any) {
                  const match = /language-(\w+)/.exec(className || '')
                  // console.log('code chat', match)
                  return !inline && match ? (
                    <SyntaxHighlighter
                      {...rest}
                      children={children}
                      language={match[1]}
                      PreTag="div"
                    />
                  ) : (
                    <code {...props} className={`code-line`}>
                      {children}
                    </code>
                  )
                }
              }}
            /> }
          </Paper>
          {(!isSent && id !== "DEFAULT") && (
            <Stack
              direction="row"
              justifyContent={isSent ? 'flex-end' : 'flex-start'}
              spacing={0.5}
              sx={{
                position: 'absolute',
                top: '50%',
                p: 1.5,
                ...(isSent
                  ? {
                      left: 0,
                      transform: 'translate(-100%, -50%)',
                    }
                  : {
                      right: 0,
                      transform: 'translate(100%, -50%)',
                    }),
              }}
            >
              <Button
                size="small"
                onClick={() => {
                  setIsLiked((prevState) => !prevState);
                  // console.log(id);
                }}
              >
                {isLiked ? '❤️' : <FavoriteBorderIcon />}
              </Button>
              <Button
                size="small"
                onClick={handleContentCopyClick}
              >
                {isCelebrated ? <ContentCopyIcon /> : <ContentCopyIcon />}
              </Button>
            </Stack>
          )}
        </Box>
    </Box>
  );
}
