import React from 'react';
import Box from '@mui/material/Box';
import Header from '../../components/Header';
import RAGPane from '../../components/ChatRAGPane';
import Sidebar from '../../components/Sidebar';

const MainContent: React.FC = () => {
  return (
    <>
      <Sidebar />
      <Header />
      <Box component="main" className="MainContent" sx={{ flex: 1 }}>
        <RAGPane />
      </Box>
    </>
  );
};

export default MainContent;
