// import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";

import { useIsAuthenticated } from "@azure/msal-react";
import SignIn from "./pages/login/login";
import { useSelector } from "react-redux";
import { CheckUserRoles } from "./utils";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import RAG from "./pages/chat/RAG";
import LLM from "./pages/chat/LLM";

import theme from "./style/theme";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import Index from "./pages/admin/Index";
import Document from "./pages/admin/Document";
import { useEffect } from "react";

export default function JoyMessagesTemplate() {
  const isAuthenticated = useIsAuthenticated();
  const userInfo = useSelector((state: any) => state.user);

  useEffect(() => {
    if (!isAuthenticated) {
      sessionStorage.clear();
    }
  }, [isAuthenticated]);

  if (userInfo.role === null && isAuthenticated)
    CheckUserRoles(sessionStorage.getItem("idToken"));

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {!isAuthenticated || userInfo.role === null ? (
        <Box sx={{ display: "flex", minHeight: "100dvh", minWidth: "100dvw", justifyContent: "center" }}>
          <SignIn />
        </Box>
      ) : (
        <Box sx={{ display: "flex", minHeight: "100dvh" }}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<RAG />} />
              <Route path="/gpt" element={<LLM />} />
              <Route path='/console/index' element={<Index />} />
              <Route path='/console/document' element={<Document />} />
            </Routes>
          </BrowserRouter>
        </Box>
      )}
    </ThemeProvider>
  );
}
