import Api from './apiConfig';

export const getIndexList = () => {
	return Api.get(`/console/indexs`);
};

export const getDocumentList = (newValue) => {
	return Api.get(`/console/documents?category=${newValue}`);
};

export const getDocument = (newValue) => {
	return Api.get(`/console/document?id=${newValue}`);
};

export const updateContents = (data) => {
	return Api.post(`/console/document/markdown`, data);
}