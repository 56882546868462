import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import ChatBubbleRAG from './ChatBubbleRAG';
import MessageInput from './MessageInput';
import MessagesPaneHeader from './MessagesPaneHeader';
import { MessageProps } from '../types';
import { useMsal } from '@azure/msal-react';
import { RootState } from '../store/AriStore';
import { generateRandomString } from '../utils';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material';
import { api } from '../api/streamFetch';

function formattedDate() {
  const formatter = new Intl.DateTimeFormat('ko-KR', options);
  const [{ value: month },,{ value: day },,{ value: hour },,{ value: minute }] = formatter.formatToParts(new Date());
  const formattedDate = `${month}.${day} ${hour}:${minute}`;

  return formattedDate;
}

function sleep(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const options: Intl.DateTimeFormatOptions = {
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,
};


export default function MessagesPane() {
  const [open, setOpen] = useState(false);
  const [document, setDocument] = useState();
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const onShowCitation = (citation: string, index: number) => {
    setDocument(citation as any);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const menuInfo = useSelector((state: RootState) => state.menu);

  const [chatMessages, setChatMessages] = useState<MessageProps[]>([{
    id: "DEFAULT",
    sender: 'ASSISTANT',
    account: null,
    content: menuInfo.selectedWelcome,
    timestamp: formattedDate(),
  }]);

  const [textAreaValue, setTextAreaValue] = useState('');
  const chatCompletionData = useSelector((state: any) => state.chat);
  const { accounts } = useMsal();

  const fetchData = async () => {
    setChatMessages([
      ...chatMessages,
      {
        id: "DEFAULT",
        sender: 'ASSISTANT',
        account: null,
        content: 'Loading...',
        timestamp: new Intl.DateTimeFormat('ko-KR', options).format(new Date())
      },
    ]);

    const api_host = process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : 'https://gsr-dt-api-p001.azurewebsites.net';
    // const response = await fetch(menuInfo.selectedIndex !== 500 ? `${api_host}/stream/claude` : `${api_host}/stream/gpt`, {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'Authorization': `Bearer ${sessionStorage.getItem("idToken")}`,
    //   },
    //   credentials: 'include',
    //   mode: 'cors',
    //   body: JSON.stringify({ account: accounts[0]['username'], message: chatMessages, menuIndex: menuInfo.selectedIndex }),
    // });

    const response = await api.post(menuInfo.selectedIndex !== 500 ? `/stream/claude` : `/stream/gpt`, 
      { account: accounts[0]['username'], message: chatMessages, menuIndex: menuInfo.selectedIndex }
    );

    if (response.body) {
      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      while (true) {
        const { done, value } = await reader.read();

        if (done) {
          console.log('Receive Stream Data Done!');
          break;
        }
        const chunk = decoder.decode(value, { stream: true });
        const newMessages = chunk.split('\n\n')
          .map(message => {
            return message.split('data:').join('');
          });
        // eslint-disable-next-line no-loop-func
        msg = msg + newMessages.join('');

        await sleep(33);

        setChatMessages([
          ...chatMessages,
          {
            id: chatMessages.length > 0 
              ? chatMessages[chatMessages.length - 1].id 
              : "ASSISTANT:" + Math.random().toString(36).substring(2, 12),
            sender: 'ASSISTANT',
            account: null,
            content: msg ? msg : 'Loading..',
            timestamp: formattedDate(),
          },
        ]);;
      }
    }
  };

  var msg: string | null = '';

  useEffect(() => {
    if (chatMessages.length > 1 && chatMessages[chatMessages.length - 1]?.sender !== 'ASSISTANT') {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatMessages]);

  useEffect(() => {
    setChatMessages([{
      id: "DEFAULT",
      sender: 'ASSISTANT',
      account: null,
      content: menuInfo.selectedWelcome,
      timestamp: formattedDate(),
    }])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuInfo.selectedIndex]);

  useEffect(() => {
    if (chatMessages[chatMessages.length - 1]?.sender !== 'ASSISTANT') {
      setChatMessages([
        ...chatMessages,
        {
          id: generateRandomString(30),
          sender: chatCompletionData.messageType,
          account: accounts[0]['username'],
          content: chatCompletionData.content,
          timestamp: formattedDate(),
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatCompletionData.content]); // Removed dispatch from dependency array

  return (
    <Paper
      key={menuInfo.selectedIndex} // Ensure re-render when chat prop changes
      sx={{
        height: { xs: '100dvh', lg: '100dvh' },
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'background.level1',
        pt: { xs: 'var(--Header-height)', sm: 0 },
      }}
    >
        <>
      <MessagesPaneHeader menuTitle={menuInfo.selectedName} />
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          minHeight: 0,
          px: 2,
          py: 3,
          overflowY: 'scroll',
          flexDirection: 'column-reverse',
          backgroundColor: '#F0F4F8',
        }}
      >
        <Stack spacing={2} justifyContent="flex-end">
          {chatMessages.map((message: MessageProps, index: number) => {
            const isYou = message.sender !== 'ASSISTANT';
            return (
              <Stack
                key={index}
                direction="row"
                spacing={2}
                flexDirection={isYou ? 'row-reverse' : 'row'}
              >
                <ChatBubbleRAG variant={isYou ? 'sent' : 'received'} {...message} onCitationClicked={c => onShowCitation(c, index)}/>
              </Stack>
            );
          })}
        </Stack>
      </Box>
      <MessageInput
        textAreaValue={textAreaValue}
        setTextAreaValue={setTextAreaValue}
        onSubmit={() => {
          setChatMessages([
            ...chatMessages,
            {
              id: generateRandomString(30),
              sender: accounts[0]['name'],
              account: accounts[0]['username'],
              content: textAreaValue,
              timestamp: formattedDate(),
            },
          ]);
        }}
      />
      </>
      <Dialog
        open={open}
        onClose={handleClose}
        fullScreen={fullScreen}
        sx={{zIndex: 10002, 
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "900px",  // Set your width here
                "& .MuiDialogContent-root": {
                  padding: 0,
                },
              },
            },
          }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Reference
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {document !== undefined && document !== "" ? (<Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
            <Viewer 
              plugins={[defaultLayoutPluginInstance]}
              fileUrl={`https://gsrstorageaccountp001.blob.core.windows.net/content/${document}`} />
          </Worker>) : null}
        </DialogContent>
      </Dialog>
    </Paper>
  );
}